import { createSlice } from '@reduxjs/toolkit';
import {
  editCompensationOrder,
  getCompensationOrders,
  changeCompensationOrderByCommand,
} from './compensationOrders.thunk';
import { OrdersInitialState } from 'models/compensationOrders.interface';

const initialState: OrdersInitialState = {
  compensationOrders: [],
  pages: null,
  total: null,
  loading: false,
  error: null,
};

const compensationOrdersSlice = createSlice({
  name: 'compensationOrders',
  initialState,
  reducers: {},
  extraReducers: {
    [getCompensationOrders.pending.toString()]: state => {
      state.compensationOrders = [];
      state.loading = true;
      state.error = null;
    },
    [getCompensationOrders.fulfilled.toString()]: (state, { payload }) => {
      state.compensationOrders = payload.items;
      state.pages = payload.pages;
      state.total = payload.total;
      state.loading = false;
    },
    [getCompensationOrders.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [changeCompensationOrderByCommand.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [changeCompensationOrderByCommand.fulfilled.toString()]: (state, { payload }) => {
      const currentIndex = state.compensationOrders?.findIndex(order => order.employee.id === payload.employee.id);
      state.compensationOrders[currentIndex] = {
        ...payload,
      };
      state.loading = false;
    },
    [changeCompensationOrderByCommand.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    [editCompensationOrder.pending.toString()]: state => {
      state.loading = true;
      state.error = null;
    },
    [editCompensationOrder.fulfilled.toString()]: (state, action) => {
      const updatedOrders = state.compensationOrders.map(order =>
        order.id === action.meta.arg.id ? { ...order, ...action.meta.arg.value } : order
      );

      state.compensationOrders = updatedOrders;
      state.loading = false;
    },
    [editCompensationOrder.rejected.toString()]: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
  },
});

export default compensationOrdersSlice.reducer;
