export enum DATE_PICKER {
  dateFormatToYear = 'yyyy/MM/dd',
  dateFormatFullYear = 'yyyy',
  dateFormatQuarter = 'yyyy, QQQ',
  placeholder = 'yyyy/mm/dd',
  dateFormatToPayload = 'yyyy-MM-dd',
  dateFormatMonth = 'MMM, yyyy',
  dateFormatFullMonth = 'MMMM',
  dateFormatHoliday = 'MMM dd, yyyy EEEE',
  dateFormatMonthAndDay = 'MMM dd, yyyy',
  dateFormatToStandard = 'dd/MM/yyyy',
}

export enum POINTER {
  pointer = 'pointer',
  default = 'default',
}

export const PER_PAGE_DATA = [50, 100, 250];

export const PAGES_NUMBER_LIMIT = 5;

export const CURRENCY = ['USD', 'UAH'];

export const MONTH_COUNT = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

export const RESOURCE_REQUEST_STATUSES = [
  'All',
  'Approved',
  'Draft',
  'Fin-check',
  'In Review',
  'Purchased',
  'Rejected',
  'Requested',
  'Waiting for confirmation',
];

export const BILLABLE_TYPES = ['All', 'Billable', 'Non Billable'];
export const PAYMENT_TYPES = ['Billable', 'Non Billable'];

export const BILLING_SOURCES = ['All', 'External', 'Internal'];

export const SERVICE_TYPES = [
  '.NET',
  'Consulting',
  'Blockchain',
  'Android',
  'BA',
  'BI',
  'Customer Support',
  'Delivery',
  'Design',
  'DevOps',
  'Flutter',
  'iOS',
  'Java',
  'JS',
  'Markup',
  'Node.JS',
  'PHP',
  'PM',
  'PO',
  'QA',
  'React Native',
  'Python',
  'Management',
  'Solution Architect',
  'Data Engineering',
];
