export interface RequestParams {
  available: boolean;
  date: string;
  fromDate: string;
  toDate: string;
  team: number;
  department: number;
  delivery: string;
  projectKey: string;
  pir: string;
  salesGrade: number;
  search: string;
  status: string;
  statusExcluded: boolean;
  orderBy: string;
  billingType: string;
  billingSource: string;
  expand?: string | null;
  costs?: string | null;
  cycle?: string | null;
  page?: number | null;
  size?: number | null;
  mentor: string;
  statusCategory: 'workforce-requestable';
}

export function cleanEmptyParams(a: Partial<RequestParams>) {
  return Object.entries(a).reduce((acc, [k, v]) => ({ ...acc, ...(v ? { [k]: v } : {}) }), {});
}
