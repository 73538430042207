export const COLORS = {
  blue: '#4f75ff',
  indigo: '#6610f2',
  purple: '#824CFF',
  pink: '#e83e8c',
  red: '#FF4F5B',
  orange: '#FFA447',
  yellow: '#ffc107',
  green: '#46C869',
  teal: '#51BED8',
  cyan: '#17a2b8',
  white: '#fff',
  gray: '#7F8495',
  gray100: '#f8f9fa',
  gray200: '#e9ecef',
  gray300: '#dee2e6',
  gray400: '#ced4da',
  gray500: '#adb5bd',
  gray600: '#6c757d',
  gray700: '#495057',
  gray800: '#343a40',
  gray900: '#212529',
  darkGray: '#40475D',
  mdGray: '#5f6679',
  black: '#000',
  transparent: 'transparent',
};

export const HOVER_COLORS = {
  blue: '#DCE3FF',
  purple: '#E6DBFF',
  red: '#FFEDEF',
  orange: '#FFEDDA',
  green: '#ECF9F0',
  teal: '#EEF8FB',
  gray: '#F0F4F6',
};
